import React from 'react';
import { Link } from 'gatsby';
import HTMLParser from 'html-react-parser';
import ReactPaginate from 'react-paginate';
import { differenceInMilliseconds, format, isSameMonth, isValid } from 'date-fns/esm';
import { TagCloud } from 'react-tagcloud';
import clsx from 'clsx';

import { constructLink, formColor } from '../../../../../helper';
import SectionMedia from '../../LayoutComponents/SectionMedia';
import CustomizableButton from '../../../../CustomizableButton';
import getCategoryIds from '../../../../../helpers/getCategoryIds';
import getCategoryStyle from '../../../../../helpers/getCategoryStyle';
import Image from '../../../../Image';

import * as styles from './styles.module.css';
import './cloud.css';

class ArticleMotherLayout extends React.PureComponent {
  static formCounts(data, color) {
    const counts = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        if (item && item.tags) {
          item.tags.forEach((tag) => {
            let exists = false;
            counts.some((t, i) => {
              if (t.value === tag) {
                counts[i].count += 1;
                exists = true;
              }

              return exists;
            });

            if (exists === false) {
              counts.push({
                value: tag,
                count: 1,
                color,
              });
            }
          });
        }
      });
    }

    return counts;
  }

  constructor(props) {
    super(props);
    let articles = [...(props.section.articles || [])];
    if (articles && props.category) {
      const categoryIds = getCategoryIds(props.category);
      articles = articles.filter((a) => categoryIds?.includes(a.categoryId));
    }

    const categoryStyle = getCategoryStyle(
      props.section?.styles?.categories || [],
      props.category?._id,
      props.themeData?.colors || [],
      props.buttons,
      props.section?.styles,
      props.section?.styles?.authors?.[0]?._id,
    );

    let articleCount;
    if (categoryStyle) ({ articleCount } = categoryStyle);
    const counts = ArticleMotherLayout.formCounts(articles, props.themeData.colors[0]);
    const pageCount = articles ? Math.ceil(articles.length / articleCount) : 0;
    const linkBtnStyles = props.buttons.find((btn) => btn.id === categoryStyle?.linkBtnId);

    if (categoryStyle?.sortOrder === 'old') articles.reverse();

    this.state = {
      articles,
      linkBtnStyles,
      articleCount,
      pageCount,
      currentPage: 0,
      searchResults: null,
      counts,
      categoryStyle,
    };

    this.createText = this.createText.bind(this);
    this.createImage = this.createImage.bind(this);
    this.handleStringSearch = this.handleStringSearch.bind(this);
    this.handleDateSearch = this.handleDateSearch.bind(this);
    this.handleTagSearch = this.handleTagSearch.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.createAuthorInfo = this.createAuthorInfo.bind(this);
    this.createSearch = this.createSearch.bind(this);
    this.createArchive = this.createArchive.bind(this);
    this.createTagCloud = this.createTagCloud.bind(this);
    this.createArticles = this.createArticles.bind(this);
    this.createSearchHeader = this.createSearchHeader.bind(this);
    this.createSearchResults = this.createSearchResults.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  handleStringSearch(event) {
    if (event.type === 'mousedown' || (event.type === 'keypress' && event.key === 'Enter')) {
      const articles = [];
      let { value } = event.target;
      if (event.type === 'mousedown') {
        ({ value } = document.getElementById(`${this.props.section._id}_search`));
      }
      const origValue = value;
      value = value.trim().toLowerCase();

      if (value && this.state.articles) {
        this.state.articles.forEach((article, index) => {
          if (
            article.title.toLowerCase().includes(value) ||
            article.excerpt.toLowerCase().includes(value) ||
            (article.author && article.author.name && article.author.name.toLowerCase().includes(value)) ||
            article.tags.includes(value)
          ) {
            articles.push(index);
          } else {
            article.data.every((cntnt) => {
              let notFound = true;
              if (
                ((cntnt.type === 'PARAGRAPH/PARAGRAPH' ||
                  cntnt.type === 'PARAGRAPH/QUOTE' ||
                  cntnt.type === 'HEADINGS/HEADING-TWO') &&
                  cntnt.text.toLowerCase().includes(value)) ||
                (cntnt.type === 'IMAGE' &&
                  cntnt.content &&
                  cntnt.content.alt &&
                  cntnt.content.alt.toLowerCase().includes(value))
              ) {
                articles.push(index);
                notFound = false;
              }

              return notFound;
            });
          }
        });

        const pageCount = Math.ceil(articles.length / this.state.articleCount);
        this.setState({
          searchResults: {
            type: 'search',
            search: origValue,
            articles,
          },
          pageCount,
          currentPage: 0,
        });
      } else this.clearSearch();
    }
  }

  handleDateSearch(event) {
    const { value } = event.target.options[event.target.selectedIndex].dataset;

    if (value !== 'select' && this.state.articles) {
      const articles = [];
      this.state.articles.forEach((article, index) => {
        if (isSameMonth(new Date(value), new Date(article.pubDate))) articles.push(index);
      });

      const pageCount = Math.ceil(articles.length / this.state.articleCount);
      this.setState({
        searchResults: {
          type: 'archive',
          search: value,
          articles,
        },
        pageCount,
        currentPage: 0,
      });
    } else this.clearSearch();
  }

  handleTagSearch(event) {
    const { value } = event.target !== undefined ? event.target.dataset : event;
    const articles = [];
    if (this.state.articles) {
      this.state.articles.forEach((article, index) => {
        if (article.tags.includes(value)) articles.push(index);
      });
    }

    const pageCount = Math.ceil(articles.length / this.state.articleCount);
    this.setState({
      searchResults: {
        type: 'tag',
        search: value,
        articles,
      },
      pageCount,
      currentPage: 0,
    });
  }

  handlePageClick(data) {
    const currentPage = data.selected;
    this.setState({
      currentPage,
    });
  }

  createText(item, type, index, subIndex) {
    const id = `${this.props.section._id}_${type.startsWith('TITLE') ? 'Title' : 'Paragraph'}_${
      subIndex !== undefined ? subIndex : ''
    }${index}_section`;
    let content;
    let styleNames;
    if (type === 'TITLE') {
      content = `<h3>${item}</h3>`;
      styleNames = styles.articleTitle;
    } else if (type.startsWith('PARAGRAPH')) {
      content = `<span>${item}</span>`;
      styleNames = styles.articleText;
    }

    const text = <div>{HTMLParser(content)}</div>;

    return (
      <div id={id} key={id} className={styleNames}>
        {text}
      </div>
    );
  }

  createImage(item, index, layout) {
    let elem;
    if (item.icon !== null || item.id !== '') {
      const imgWrapperStyle = layout === 'columns' ? '' : 'imageContent5';
      let wrapperStyle = styles[`articleImageWrapper${item.icon ? 'Icon' : ''}`];
      if (layout === 'columns') wrapperStyle = styles.articleImageWrapperColumns;

      elem = (
        <div className={wrapperStyle} key={`${this.props.section._id}_Image}_${index}_section`}>
          <SectionMedia
            mediaType={item.icon ? 'ICON' : 'IMAGE'}
            wrapperStyleName={imgWrapperStyle}
            imageStyleName="articleImage"
            alt={item.alt}
            data={item}
            images={this.props.images}
            pagePathList={this.props.pagePathList}
            articlePathList={this.props.articlePathList}
            filePathList={this.props.filePathList}
            categoryPathList={this.props.categoryPathList}
            colors={this.props.themeData.colors}
          />
        </div>
      );
    }

    return elem;
  }

  createAuthorInfo() {
    const author = this.props.section.styles.authors.find((a) => a._id === this.state.categoryStyle.owner);
    let value;
    if (author !== undefined) {
      const links = [];
      if (author.links.length > 0) {
        author.links.forEach((l, i) => {
          if (l.active === true && l.image.icon !== null) {
            const { link } = constructLink(
              l.image.linkObj,
              this.props.pagePathList,
              this.props.articlePathList,
              this.props.categoryPathList,
            );

            let tab;
            if (l.image.linkObj) tab = l.image.linkObj.openLinkInNewTab;

            const target = tab ? '_blank' : '_self';
            const rel = tab ? 'noopener noreferrer' : '';
            const newLink = (
              <a
                key={`${this.props.section._id}_ArticleMother_Owner_Link_${i}`}
                href={link}
                target={target}
                rel={rel}
                draggable="false"
              >
                <i
                  className={`entypo ${l.image.icon.class}`}
                  aria-hidden="true"
                  style={{
                    fontSize: l.image.icon.size,
                    color: l.image.icon.color,
                    marginRight: '4px',
                  }}
                />
              </a>
            );

            links.push(newLink);
          }

          return null;
        });
      }

      value = (
        <div>
          {author.image !== '' && (
            <div className={styles.authorImage}>
              <SectionMedia
                mediaType={author.image.icon ? 'ICON' : 'IMAGE'}
                wrapperStyleName="imageContent3"
                imageStyleName=""
                iconStyleName=""
                src={author.image.CDNLink ? author.image.CDNLink : `${process.env.IMAGES_CDN}/${author.image.src}`}
                alt={author.image.alt}
                data={author.image}
                images={this.props.images}
                pagePathList={this.props.pagePathList}
                articlePathList={this.props.articlePathList}
                filePathList={this.props.filePathList}
                categoryPathList={this.props.categoryPathList}
                colors={this.props.themeData.colors}
              />
            </div>
          )}
          {author.name !== '' && (
            <div className={styles.textCenter}>
              <h4 className={styles.authorText}>{author.name}</h4>
            </div>
          )}
          {author.desc !== '' && (
            <div className={styles.textCenter}>
              <span className={styles.authorText}>{HTMLParser(author.desc)}</span>
            </div>
          )}
          {links.length > 0 && <div className={styles.linksWrapper}>{links}</div>}
        </div>
      );
    }

    return value;
  }

  createSearch() {
    return (
      <div className={styles.searchWrapper}>
        <input
          id={`${this.props.section._id}_search`}
          type="text"
          placeholder={this.state.categoryStyle.searchText}
          className={styles.searchInput}
          onKeyPress={this.handleStringSearch}
        />
        <button className={styles.searchBtn} onMouseDown={this.handleStringSearch}>
          <i className="entypo icon-search" />
        </button>
      </div>
    );
  }

  createArchive(title) {
    const months = [];
    const options = [
      <option data-value="select" key={`${this.props.section._id}_Archive_Select`}>
        {this.props.section.styles.selectText}
      </option>,
    ];

    if (this.state.articles) {
      this.state.articles.forEach((item) => {
        let found = false;
        months.some((month) => {
          if (isSameMonth(new Date(month), new Date(item.pubDate))) {
            found = true;
          }

          return found;
        });

        if (found === false) {
          months.push(item.pubDate);
        }
      });
    }

    months.sort((left, right) => differenceInMilliseconds(Date.UTC(left), Date.UTC(right)));

    months.forEach((month) => {
      if (isValid(new Date(month))) {
        options.push(
          <option data-value={month} key={`${this.props.section._id}_Archive_${format(new Date(month), 'MM yyyy')}`}>
            {format(new Date(month), 'MM yyyy')}
          </option>,
        );
      }
    });

    return (
      <div className={styles.elementWrapper}>
        {title !== false && (
          <div className={styles.text}>
            <h3>{this.props.section.styles.archiveText}</h3>
          </div>
        )}
        <select className={styles.select} onChange={this.handleDateSearch}>
          {options}
        </select>
      </div>
    );
  }

  createTagCloud() {
    let value;
    if (this.state.counts.length > 0) {
      value = (
        <div className={styles.elementWrapper}>
          <div className={styles.text}>
            <h3>{this.props.section.styles.tagsText}</h3>
          </div>
          <TagCloud
            minSize={10}
            maxSize={35}
            disableRandomColor
            tags={this.state.counts}
            className="simple-cloud"
            onClick={this.handleTagSearch}
          />
        </div>
      );
    }

    return value;
  }

  createArticles(sidebar) {
    const articles = [];
    const { layout = 'list' } = this.state.categoryStyle;
    let columns = 1;
    if (layout === 'columns' && sidebar) columns = 2;
    else if (layout === 'columns') columns = 3;
    const endIndex =
      this.state.currentPage * this.state.articleCount + this.state.articleCount > this.state.articles.length
        ? this.state.articles.length
        : this.state.currentPage * this.state.articleCount + this.state.articleCount;
    for (let i = this.state.currentPage * this.state.articleCount; i < endIndex; i += 1) {
      const to = this.state.articles[i].link.target;

      let img;
      if (this.state.categoryStyle.showThumbnails === true) {
        img = (
          <div key={`${this.props.section._id}_article_${i}_image_link`}>
            <Link to={to} draggable="false">
              {this.createImage(this.state.articles[i].image, i, layout)}
            </Link>
          </div>
        );
      }

      let excerpt;
      if (this.state.categoryStyle.showExcerpt === true) {
        excerpt = this.createText(this.state.articles[i].excerpt, 'PARAGRAPH', i);
      }

      let pub;
      if (this.state.categoryStyle.showDate !== false) {
        pub = (
          <div key={`${this.props.section._id}_article_${i}_short_pub`}>
            <span>{format(new Date(this.state.articles[i].pubDate), 'd.M.yyyy')}</span>
          </div>
        );
      }

      const title = (
        <div key={`${this.props.section._id}_article_${i}_title_link`}>
          <Link to={to} draggable="false">
            {this.createText(this.state.articles[i].title, 'TITLE', i)}
          </Link>
        </div>
      );
      const link = (
        <div key={`${this.props.section._id}_article_${i}_link`}>
          <Link to={to} style={{ display: 'inline-block' }} draggable="false">
            {this.state.categoryStyle.linkType !== 'button' ? (
              this.state.categoryStyle.linkText
            ) : (
              <CustomizableButton
                label={this.state.categoryStyle.linkText}
                data={{ styles: this.state.linkBtnStyles }}
                themeData={this.props.themeData}
                button="Button"
                fullWidth={false}
              />
            )}
          </Link>
        </div>
      );
      const article = (
        <div
          key={`${this.props.section._id}_article_${i}_preview`}
          className={`${styles.articleWrapper} col-12 col-md-${12 / columns}`}
        >
          {[img, pub, title, excerpt, link]}
          {layout === 'list' && <div className={styles.line} />}
        </div>
      );

      articles.push(article);
    }

    return articles;
  }

  createSearchHeader() {
    let titleText;
    let resultText;
    let field;
    if (this.state.searchResults.articles.length === 0) {
      titleText = this.props.section.styles.noResults;
    } else if (this.state.searchResults.type === 'search') {
      titleText = this.props.section.styles.searchResults;
      resultText = (
        <div className={styles.searchText}>
          {`${this.state.searchResults.articles.length} ${this.props.section.styles.searchResult} "${this.state.searchResults.search}"`}
        </div>
      );
      field = <div className={styles.field}>{this.createSearch()}</div>;
    } else if (this.state.searchResults.type === 'archive') {
      const date = format(new Date(this.state.searchResults.search), 'MM yyyy');
      titleText = `${this.props.section.styles.archiveText}: ${date}`;
      resultText = (
        <div className={styles.searchText}>
          {`${this.state.searchResults.articles.length} ${this.props.section.styles.dateResult} `}
          <span className={styles.field}>{date}</span>
        </div>
      );
      field = <div className={styles.field}>{this.createArchive(false)}</div>;
    } else if (this.state.searchResults.type === 'tag') {
      titleText = `${this.props.section.styles.tag}: ${this.state.searchResults.search}`;
      resultText = (
        <div className={styles.searchText}>
          {`${this.state.searchResults.articles.length} ${this.props.section.styles.tagResult} `}
          <span className={styles.field}>{this.state.searchResults.search}</span>
        </div>
      );
    }

    return (
      <>
        <div className={styles.searchTitle}>
          <h1>{titleText}</h1>
        </div>
        {this.state.searchResults.articles.length > 0 && (
          <>
            <span>{resultText}</span>
            {field}
            <div className={styles.line} />
          </>
        )}
      </>
    );
  }

  createSearchResults() {
    const articles = [];
    if (this.state.searchResults.articles.length > 0 && this.state.articles) {
      const endIndex =
        this.state.currentPage * this.state.articleCount + this.state.articleCount >
        this.state.searchResults.articles.length
          ? this.state.searchResults.articles.length
          : this.state.currentPage * this.state.articleCount + this.state.articleCount;
      for (let i = this.state.currentPage * this.state.articleCount; i < endIndex; i += 1) {
        const index = this.state.searchResults.articles[i];
        const img = this.createImage(this.state.articles[index].image, index);
        const pub = format(new Date(this.state.articles[index].pubDate), 'd.M.yyyy');
        const tags = [];
        this.state.articles[index].tags.forEach((tag) => {
          const btn = (
            <button
              style={{ color: this.props.themeData.colors[0] }}
              data-value={tag}
              className={styles.tagBtn}
              onMouseDown={this.handleTagSearch}
            >
              {tag}
            </button>
          );
          tags.push(btn);
        });
        const title = this.createText(this.state.articles[index].title, 'TITLE', index);
        const excerpt = this.createText(this.state.articles[index].excerpt, 'PARAGRAPH', index);
        const to = this.state.articles[index].link.target;

        const link = (
          <div key={`${this.props.section._id}_article_${index}_link`}>
            <Link
              id={`${this.props.section._id}_ArticleLink_${index}`}
              to={to}
              style={{ display: 'inline-block' }}
              draggable="false"
            >
              {this.state.categoryStyle.linkType !== 'button' ? (
                this.state.categoryStyle.linkText
              ) : (
                <CustomizableButton
                  label={this.state.categoryStyle.linkText}
                  data={{ styles: this.state.linkBtnStyles }}
                  themeData={this.props.themeData}
                  button="Button"
                  fullWidth={false}
                />
              )}
            </Link>
          </div>
        );
        const article = (
          <div key={`${this.props.section._id}_article_${index}_preview`} className={`row ${styles.searchResult}`}>
            <div className="col-12 col-sm-8">
              <div className={styles.dateTag}>
                <i className={`entypo icon-calendar ${styles.icon1}`} />
                {pub}
                <i className={`entypo icon-mouse ${styles.icon2}`} />
                <span>
                  {tags.map((tag, idx) => {
                    let text = ', ';
                    if (idx === tags.length - 1) {
                      text = '';
                    }

                    return (
                      <span key={`${this.props.section._id}_${index}_TagSpan_${this.state.articles[index].tags[idx]}`}>
                        {tag}
                        {text}
                      </span>
                    );
                  })}
                </span>
              </div>
              <div>{title}</div>
              <div>{excerpt}</div>
              <div>{link}</div>
            </div>
            <div className={`col-0 col-sm-4 ${styles.resultImage}`}>{img}</div>
            <div className={`col-12 ${styles.line}`} />
          </div>
        );

        articles.push(article);
      }
    }

    return articles;
  }

  clearSearch() {
    let pageCount = 0;
    if (this.state.articles) {
      const index = this.state.articles.length - 1;
      const { articleCount } = this.state.articles[index];
      pageCount = Math.ceil((this.state.articles.length - 1) / articleCount);
    }

    this.setState({
      searchResults: null,
      pageCount,
      currentPage: 0,
    });
  }

  render() {
    const { sidebar, fontColor, layout = 'list' } = this.state.categoryStyle;
    const sidebarActive =
      this.state.categoryStyle.showSidebar !== false &&
      (sidebar?.authorInfo === true ||
        sidebar?.search === true ||
        sidebar?.archive === true ||
        sidebar?.tags === true) &&
      this.state.searchResults === null;
    const contentCols = sidebarActive === true || layout === 'list' ? 'col-12 col-sm-8' : 'col-12';
    const contentStyle = sidebarActive === true ? styles.contentPadding : undefined;
    const otherCols = sidebarActive === true ? 'col-12 col-sm-4' : undefined;
    const otherStyle = sidebarActive === true ? styles.otherPadding : undefined;

    const fontColorData = fontColor || { color: '#000000', opacity: 1 };
    const color = formColor(
      { solid: fontColorData.color },
      false,
      fontColorData.opacity,
      undefined,
      this.props.themeData.colors,
    ).backgroundColor;

    let searchHeader;
    let categoryHeader;
    if (this.props.category) {
      const nameActive = this.state.categoryStyle.cNameActive ?? true;
      const descActive = this.state.categoryStyle.cDescActive ?? true;
      const headerTextColor = formColor(
        { solid: this.state.categoryStyle?.headerColor?.color ?? '#000000' },
        undefined,
        this.state.categoryStyle?.headerColor?.opacity ?? 1,
        undefined,
        this.props.themeData.colors,
      )?.backgroundColor;
      const headerBgColor = formColor(
        { solid: this.state.categoryStyle?.headerBg?.color ?? '#ffffff' },
        undefined,
        this.state.categoryStyle?.headerBg?.opacity ?? 1,
        undefined,
        this.props.themeData.colors,
      );

      const padding =
        !this.state.categoryStyle.headerImage &&
        (this.state.categoryStyle?.headerBg?.color ?? '#ffffff') === this.props.backgroundColor?.solid
          ? { paddingTop: 0, paddingBottom: 0 }
          : {};
      categoryHeader = (
        <>
          <div style={{ ...headerBgColor, ...padding, ...this.props.paddingTop }} className={styles.header}>
            {this.state.categoryStyle.headerImage && this.props.category?.image?.id && (
              <div className={styles.headerImage}>
                <Image id={this.props.category?.image?.id} images={this.props.images} />
              </div>
            )}
            <div className="container">
              <div
                className="row"
                style={layout === 'list' && !sidebarActive ? { justifyContent: 'center' } : undefined}
              >
                <div className={sidebarActive === true || layout === 'list' ? 'col-12 col-sm-8' : 'col-12'}>
                  {nameActive && (
                    <div className={styles.categoryTitle}>
                      <h1 style={{ color: headerTextColor }}>{this.props.category.name}</h1>
                    </div>
                  )}
                  {this.props.category.description && descActive && (
                    <span style={{ color: headerTextColor, position: 'relative', zIndex: 1 }}>
                      {this.props.category.description}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.separator} />
        </>
      );
    }

    let articles;
    if (this.state.searchResults) {
      searchHeader = this.createSearchHeader();
      articles = this.createSearchResults();
    } else articles = this.createArticles(sidebarActive);

    let authorInfo;
    let search;
    let archive;
    let tags;

    if (this.state.categoryStyle.showSidebar !== false) {
      if (sidebar?.authorInfo === true && this.state.searchResults === null && this.state.categoryStyle.owner) {
        authorInfo = this.createAuthorInfo();
      }
      if (sidebar?.search === true && this.state.searchResults === null) search = this.createSearch();
      if (sidebar?.archive === true && this.state.searchResults === null) archive = this.createArchive();
      if (sidebar?.tags === true && this.state.searchResults === null) tags = this.createTagCloud();
    }

    return (
      <>
        {categoryHeader}
        <div
          className="container"
          style={{ color, paddingTop: !this.props.category ? this.props.paddingTop?.paddingTop : undefined }}
        >
          <div className="row" style={layout === 'list' ? { justifyContent: 'center' } : undefined}>
            <div className={clsx(contentCols, contentStyle)}>
              {this.state.searchResults !== null && searchHeader}
              <div className="row">{articles}</div>
              {this.state.pageCount > 1 && (
                <div className={styles.paginationWrapper}>
                  <ReactPaginate
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={`${styles.pagination}`}
                    subContainerClassName="pages pagination"
                    disabledClassName={`${styles.disabledPagination}`}
                    activeClassName={`${styles.activePage}`}
                    initialPage={0}
                    disableInitialCallback
                    forcePage={this.state.currentPage}
                  />
                </div>
              )}
            </div>
            <div className={clsx(otherCols, otherStyle)}>
              {authorInfo}
              {search}
              {archive}
              {tags}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ArticleMotherLayout;
