// extracted by mini-css-extract-plugin
export var socialContainer = "D_tB";
export var instagramLink = "D_tC";
export var socialWrapperLeft = "D_pk d_dy d_W d_bw d_bF d_bl d_bC";
export var socialContentContainer = "D_tD";
export var socialContentContainerFull = "D_tF";
export var instagramContainer = "D_tG";
export var twitterContainer = "D_tH";
export var facebookContainer = "D_tJ";
export var socialErrorContainer = "D_tK";
export var facebookContainerWide = "D_tL";
export var twitterContainerWide = "D_tM";
export var socialParagraphCenter = "D_tN";
export var instaWrapper = "D_tP";
export var SubtitleSmall = "D_nW J_nW J_t3 J_vf";
export var SubtitleNormal = "D_nX J_nX J_t3 J_vg";
export var SubtitleLarge = "D_nY J_nY J_t3 J_vh";