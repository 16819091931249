// extracted by mini-css-extract-plugin
export var lbContainerOuter = "h_m4";
export var lbContainerInner = "h_m5";
export var movingForwards = "h_m6";
export var movingForwardsOther = "h_m7";
export var movingBackwards = "h_m8";
export var movingBackwardsOther = "h_m9";
export var lbImage = "h_nb";
export var lbOtherImage = "h_nc";
export var prevButton = "h_nd";
export var nextButton = "h_nf";
export var closing = "h_ng";
export var appear = "h_nh";