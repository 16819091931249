// extracted by mini-css-extract-plugin
export var articleWrapper = "s_nj";
export var searchResult = "s_rn s_nj";
export var line = "s_fg d_fg d_cV";
export var articleTitle = "s_rp d_cm";
export var articleText = "s_nk d_cp";
export var authorImage = "s_lr d_lr d_b4 d_W d_cq";
export var authorText = "s_nL d_cd d_cq";
export var textCenter = "s_ds d_ds";
export var searchWrapper = "s_rq d_bw d_bL d_dn d_ch d_W";
export var searchInput = "s_rr d_cB d_s d_b1";
export var searchBtn = "s_rs d_bY d_by d_bL d_X d_cz d_dx";
export var text = "s_nV d_cn";
export var elementWrapper = "s_mW d_ch";
export var select = "s_rt d_s d_b1";
export var articleImageWrapper = "s_nB d_cp d_W d_R";
export var articleImageWrapperIcon = "s_nC d_cp d_W d_R";
export var articleImageWrapperColumns = "s_rv d_cp d_fd d_W";
export var contentPadding = "s_rw d_cP";
export var otherPadding = "s_rx d_cJ";
export var paginationWrapper = "s_ry";
export var pagination = "s_rz";
export var disabledPagination = "s_rB";
export var breakMe = "s_rC";
export var activePage = "s_rD";
export var next = "s_rF";
export var pages = "s_rG";
export var searchTitle = "s_rH d_cn";
export var categoryTitle = "s_rJ d_cn d_W";
export var searchText = "s_rK d_cq";
export var bold = "s_rL";
export var field = "s_rM";
export var dateTag = "s_ns d_h d_cn d_dm";
export var icon1 = "s_nt d_cL";
export var icon2 = "s_nv d_cL d_cF";
export var tagBtn = "s_nw d_bY d_cz d_dl d_dx";
export var linksWrapper = "s_nP d_cd d_ds";
export var header = "s_nl d_s d_W";
export var headerImage = "s_rN d_9 d_X d_5 d_4 d_1 d_6";
export var separator = "s_nK d_cV";
export var resultImage = "s_rP";