// extracted by mini-css-extract-plugin
export var alignLeft = "C_p6 d_fl d_bC d_dr";
export var alignCenter = "C_bL d_fm d_bz d_ds";
export var alignRight = "C_p7 d_fn d_bD d_dt";
export var menuContainer = "C_ty d_dS";
export var menuContainerFull = "C_tz d_dQ";
export var menuMainHeader = "C_jz d_jz d_s d_c0";
export var menuComponentWrapper = "C_jD d_jD d_cr";
export var menuComponentWrapperDesign2 = "C_jF d_jF d_cv";
export var menuComponentText = "C_jB d_jB d_ck";
export var menuComponentTextDesign2 = "C_jC d_jC d_cp";
export var menuImageWrapperDesign2 = "C_jG d_jG d_s d_W";