// extracted by mini-css-extract-plugin
export var alignDiscLeft = "x_sv d_fl d_bC d_dr";
export var alignLeft = "x_p6 d_fl d_bC d_dr";
export var alignDiscCenter = "x_sw d_fm d_bz d_ds";
export var alignCenter = "x_bL d_fm d_bz d_ds";
export var alignDiscRight = "x_sx d_fn d_bD d_dt";
export var alignRight = "x_p7 d_fn d_bD d_dt";
export var footerContainer = "x_sy d_dS d_bS";
export var footerContainerFull = "x_sz d_dQ d_bS";
export var footerHeader = "x_kc d_kc";
export var footerTextWrapper = "x_sB d_s";
export var footerDisclaimerWrapper = "x_kk d_kk d_cg";
export var badgeWrapper = "x_sC d_s d_bw d_bL d_bB d_bF";
export var footerDisclaimerRight = "x_kl d_kl d_bw";
export var footerDisclaimerLeft = "x_km d_km d_bw";
export var verticalTop = "x_sD d_bw d_bF d_bK d_bH";
export var firstWide = "x_sF";
export var disclaimer = "x_sG";
export var socialDisclaimer = "x_sH";
export var left = "x_sJ";
export var wide = "x_sK d_cv";
export var right = "x_sL d_bG";
export var line = "x_fg d_fh d_cr";
export var badgeDisclaimer = "x_sM d_by d_bL d_bF";
export var badgeContainer = "x_sN d_bw d_bD d_bL";
export var badge = "x_sP";
export var padding = "x_sQ d_c4";
export var end = "x_sR d_bD";
export var linkWrapper = "x_sS d_dx";
export var link = "x_m0 d_dx";
export var colWrapper = "x_sT d_ct";
export var media = "x_sV d_bt d_dv";
export var itemRight = "x_sW";
export var itemLeft = "x_sX";
export var itemCenter = "x_sY";
export var exceptionWeight = "x_sZ J_vv";