// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "z_s7 d_gs d_cp";
export var heroHeaderCenter = "z_gt d_gt d_cp d_ds";
export var heroHeaderRight = "z_gv d_gv d_cp d_dt";
export var heroParagraphLeft = "z_s8 d_gp d_cs";
export var heroParagraphCenter = "z_gq d_gq d_cs d_ds";
export var heroParagraphRight = "z_gr d_gr d_cs d_dt";
export var heroBtnWrapperLeft = "z_s9 d_dZ d_dY d_s d_bw d_bC";
export var heroBtnWrapperCenter = "z_tb d_d0 d_dY d_s d_bw d_bz";
export var heroBtnWrapperRight = "z_tc d_d1 d_dY d_s d_bw d_bD";
export var overlayBtnWrapper = "z_td d_gn d_X d_4 d_5 d_6 d_bk d_b8";
export var design4 = "z_tf d_gm d_X d_4 d_5 d_bk";
export var heroExceptionSmall = "z_tg J_tg";
export var heroExceptionNormal = "z_th J_th";
export var heroExceptionLarge = "z_tj J_tj";
export var Title1Small = "z_tk J_tk J_t3 J_t4";
export var Title1Normal = "z_tl J_tl J_t3 J_t5";
export var Title1Large = "z_tm J_tm J_t3 J_t6";
export var BodySmall = "z_tn J_tn J_t3 J_vm";
export var BodyNormal = "z_tp J_tp J_t3 J_vn";
export var BodyLarge = "z_tq J_tq J_t3 J_vp";